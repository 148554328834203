@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  /* font-family: 'Poppins', Courier, monospace; */
  font-weight: 600;
  color: black;
}

button {
  background-color: #8d96ff;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.steps-content {
  min-height: 250px;
  margin-top: 20px;
  padding: 20px;
  /* //background-color: #fafafa; */
  background-color: #ffffff;
  /* //border: 1px dashed #e9e9e9; */
  border-radius: 5px;
}

.steps-action {
  margin-top: 20px;
  text-align: center;
}

.steps-action .btn {
  margin-right: 10px;
  width: 200px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8d96ff !important;
}

.ant-tabs-ink-bar {
  background-color: #8d96ff;
}

/* Change color of active menu item */
.ant-menu-item-selected {
  color: #8d96ff !important;
}

/* Change color of underline for active menu item */
.ant-menu-item-selected::after {
  border-bottom: 2px solid #8d96ff !important;
}

/* Change color of menu item on hover */
.ant-menu-item:hover {
  color: #8d96ff !important;
}

/* Change color of underline for menu item on hover */
.ant-menu-item:hover::after {
  border-bottom: 2px solid #8d96ff !important;
}
