.countdown-block {
  width: 59px;
  padding: 5px 0;
  height: 59px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: #466399;
  margin-right: 20px;
  text-align: center;
}

.countdown-block p {
  margin-bottom: 0;
  font-size: 10px;
}

.countdown-block p.time {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
}
